import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { ShopAddons } from '@mfe/features/add-ons';
import { selectConfig } from '@mfe/shared/redux/config';
import {
  getAddOnOffers,
  refetchAddOnOffers,
  selectAddOns,
} from '@mfe/to-be-migrated/redux/addOns';

const ShopAddonsPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAddonsShop } = useSelector(selectConfig);
  const { addOnOrderId } = useSelector(selectAddOns);

  const navigateToPreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!showAddonsShop) {
      history.replace('/');
    }
  }, [history, showAddonsShop]);

  useEffect(() => {
    if (addOnOrderId) {
      dispatch(refetchAddOnOffers());
    } else {
      dispatch(getAddOnOffers());
    }
  }, []);

  if (!showAddonsShop) {
    return <></>;
  }

  return <ShopAddons navigateToPreviousPage={navigateToPreviousPage} />;
};

export default ShopAddonsPage;
