import { Button, InlineLink, Surface, Txt } from '@vst/beam';
import { OpenInNew } from '@vst/beam-icons/icons';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType, Platform } from '@mfe/shared/schema-types';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { selectConfig } from '@mfe/shared/redux/config';
import { useDetectBrowser, useScreenResolution } from '@mfe/shared/util';
import { AddOnCard } from '@mfe/features/add-ons';
import {
  useShowAddonCustomerSupportMessage,
  ActiveAddonsError,
} from '@mfe/shared/components';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';

import { AddOnsLoading } from './loading';
import useNavigate from '@mfe/services/navigation';
import { NoAddOns } from '@mfe/to-be-migrated/shared-platform-react/pages/Profile/components/Body/Content/AddOns/no-add-ons';
import React from 'react';

export const ProfileActiveAddons = () => {
  const { t } = useTranslation(['Profile', 'Global']);
  const { goTo } = useNavigate();
  const {
    currentAddOns,
    error,
    loading: currentAddOnsLoading,
    showModal,
  } = useSelector(selectAddOns);

  const dispatch = useDispatch();

  const { isSmall, isExtraSmall } = useScreenResolution();

  const isMobile = isSmall || isExtraSmall;

  const browser = useDetectBrowser();

  const { platform, showAddonsShop, showRemoveAddonFlow } =
    useSelector(selectConfig);
  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const hasAddOns = Boolean(currentAddOns?.length);
  const showAddonCustomerSupportMessage = useShowAddonCustomerSupportMessage();

  const showLink = platform !== Platform.Web || browser === 'MobileWeb';

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  const addOnsLink =
    accountType === AccountType.Residential
      ? t('Global:addOnsResidentialLink')
      : t('Global:addOnsSMBLink');

  if (currentAddOnsLoading) {
    return <AddOnsLoading />;
  }

  if (error) {
    return (
      <ActiveAddonsError
        title={t('Profile:addOns.title')}
        description={t('Profile:addOns.error.description')}
        refreshButtonLabel={t('Profile:addOns.error.refreshButton')}
      />
    );
  }

  return (
    <Surface variant="primary" radius={isMobile ? '0px' : '16px'}>
      {hasAddOns ? (
        <>
          <div className={styles['header']}>
            <Txt variant="heading5">{t('Profile:addOns.title')}</Txt>

            {showAddonsShop ? (
              <Button onClick={() => goTo('ShopAddons')} variant="secondary">
                {t('Profile:addOns.shopAddonsButtonLabel')}
              </Button>
            ) : (
              <InlineLink
                href={addOnsLink}
                openInNewLocation={true}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  openLinkInNewTab(e, addOnsLink);
                }}
              >
                <Button variant="secondary" icon={OpenInNew} iconPos="right">
                  {t('Profile:addOns.seeMoreButton')}
                </Button>
              </InlineLink>
            )}
          </div>
          <div className={styles['addons']}>
            {currentAddOns?.map((addOnOffer, index) => (
              <div className={styles['addons__item']} key={index}>
                <AddOnCard
                  id={addOnOffer.productInstanceId}
                  addOnDescription={addOnOffer.description}
                  addOnName={addOnOffer.name}
                  addOnPrice={addOnOffer.price}
                  priceWithDiscount={addOnOffer.priceWithDiscount}
                  discountName={addOnOffer.discountName}
                  addOnDiscountEndDate={addOnOffer.discountEndDate}
                  showManageLink={showRemoveAddonFlow}
                  showModal={showModal}
                  addOnState={addOnOffer.state}
                  isBundleAddOn={addOnOffer.isBundle}
                />
              </div>
            ))}
          </div>
          {showAddonCustomerSupportMessage && (
            <div className={styles['footer']}>
              <Txt variant="bodySmallRegular">
                {t('Profile:addOns.manageAddons')}
                {showLink ? (
                  <InlineLink
                    data-cy="call-customer-support-phone-number"
                    variant="primary"
                    href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
                  >
                    {t('Global:callCustomerSupportPhoneNumberDisplay')}
                  </InlineLink>
                ) : (
                  <Txt variant="bodySmallBold" component="span">
                    {t('Global:callCustomerSupportPhoneNumberDisplay')}
                  </Txt>
                )}
              </Txt>
            </div>
          )}
        </>
      ) : (
        <NoAddOns />
      )}
    </Surface>
  );
};
