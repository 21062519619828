import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Surface, Button, Spinner, IllustrativeIcon, Txt } from '@vst/beam';

import {
  AEMContentProvider,
  AemFeature,
  HeaderWithBackNavigation,
  createAemUrl,
} from '@mfe/shared/components';
import { ADDON_PRODUCT_KIND, useScreenResolution } from '@mfe/shared/util';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import {
  getAvailableNumbers,
  selectCurrentShopAddon,
} from '@mfe/to-be-migrated/redux/addOns';
import useNavigate from '@mfe/services/navigation';
import { ShopAddonsLink } from '@mfe/legacy/mv/utils/Navigation';
import { scrollToTop } from '@mfe/shared/redux/utils';

import Steps from '../steps';
import { AddonCart, CollapsibleCart } from '../cart';
import { TermsAndConditions } from '../components/TermsAndConditions';
import { getTermsAndConditions } from '../components/utils';
import styles from './styles.module.scss';
import { Alert } from '@vst/beam-icons/illustrative-icons';

const OneStepCheckout: React.FC<{
  goToConfigure: () => void;
  handleSubmit: () => void;
}> = ({ goToConfigure, handleSubmit }) => {
  const { t } = useTranslation('ShopAddons');
  const dispatch = useDispatch();
  const { goBack, replace } = useNavigate();

  const { aemBaseUrl } = useSelector(selectConfig);
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const {
    userInfo: {
      accountType,

      address: { service },
    },
  } = useSelector(selectUserInfo);
  const currentShopAddon = useSelector(selectCurrentShopAddon);
  const isVoice = currentShopAddon?.kind === ADDON_PRODUCT_KIND.voice;

  const { isMedium, isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isLarge || isExtraLarge;
  const isMobile = !isDesktop;

  const [aemFragmentLoading, setAEMFragmentLoading] = useState(true);
  const [aemFragmentError, setAEMFragmentError] = useState(false);

  useEffect(() => {
    if (!currentShopAddon) {
      replace(`/${ShopAddonsLink.ShopAddons}`);
    }
  }, [replace, currentShopAddon]);

  if (!currentShopAddon) {
    return null;
  }

  const aemURL = createAemUrl({
    baseUrl: aemBaseUrl,
    locale: userLocale,
    feature: mapAddonKindToAemUrlFragment(currentShopAddon.kind),
    accountType,
  });

  const primaryActionLabel = isVoice
    ? t('voice.addAndConfigure')
    : t('subscribeNow');

  const handlePrimaryAction = () => {
    if (isVoice) {
      dispatch(scrollToTop());
      dispatch(getAvailableNumbers(service?.postalCode as string));
      goToConfigure();
    } else {
      handleSubmit();
    }
  };

  const headerClassName = isVoice ? styles['voiceHeader'] : styles['header'];
  const termsAndConditionsContent = t(
    `termsAndConditions.${getTermsAndConditions(currentShopAddon.kind)}`
  );

  return (
    <>
      {isMobile && (
        <CollapsibleCart
          handleClick={handlePrimaryAction}
          addon={currentShopAddon}
          submitButtonLabel={primaryActionLabel}
        />
      )}
      <div className={styles['wrapper']}>
        <HeaderWithBackNavigation
          handleBackNavigation={goBack}
          title={currentShopAddon.name}
          goBackLabel={t('allAddOns')}
          additionalClassName={headerClassName}
        />
        {isVoice && (
          <Surface
            variant={isDesktop || isMedium ? 'secondary' : 'primary'}
            mb={isDesktop || isMedium ? '64px' : '8px'}
          >
            <Steps step={1} maxSteps={2} />
          </Surface>
        )}

        <div className={styles['container']}>
          <Surface className={styles['aem-fragment-surface']}>
            {aemFragmentLoading && (
              <div style={{ padding: '32px' }}>
                <Spinner label={t('cart.aemFragment.loading')} />
              </div>
            )}
            {aemFragmentError && !aemFragmentLoading ? (
              <div className={styles['addon-unavailable-wrapper']}>
                <AddonDetailsUnavailable />
              </div>
            ) : (
              <>
                <AEMContentProvider
                  id={styles['aem-fragment-container']}
                  style={{ display: aemFragmentLoading ? 'none' : 'block' }}
                  src={aemURL}
                  onLoad={() => setAEMFragmentLoading(false)}
                  onError={() => {
                    setAEMFragmentError(true);
                  }}
                />
                <div
                  style={{
                    display: aemFragmentLoading ? 'none' : 'block',
                  }}
                  className={styles['terms-container']}
                >
                  <TermsAndConditions content={termsAndConditionsContent} />
                </div>
              </>
            )}
            <div className={styles['submit-container']}>
              <Button onClick={handlePrimaryAction}>
                {primaryActionLabel}
              </Button>
            </div>
          </Surface>
          {isDesktop && (
            <AddonCart
              handleClick={handlePrimaryAction}
              addon={currentShopAddon}
              submitButtonLabel={primaryActionLabel}
            />
          )}
        </div>
      </div>
    </>
  );
};

function mapAddonKindToAemUrlFragment(kind: string) {
  if (kind === ADDON_PRODUCT_KIND.voice) {
    return AemFeature.ADD_ON_VOICE;
  }

  if (kind === ADDON_PRODUCT_KIND.shield) {
    return AemFeature.ADD_ON_SHIELD;
  }

  if (kind === ADDON_PRODUCT_KIND.easyCare) {
    return AemFeature.ADD_ON_EASYCARE;
  }

  return AemFeature.ADD_ON_STATIC_IP;
}

export default OneStepCheckout;

const AddonDetailsUnavailable = () => {
  const { t } = useTranslation('ShopAddons');

  return (
    <Surface py={'40px'} className={styles['addon-unavailable-container']}>
      <IllustrativeIcon
        icon={Alert}
        color={'teal_600'}
        style={{ height: '100px', width: '100px' }}
      />
      <Txt pt={'48px'} variant={'heading4'}>
        {t('aemFragmentError.title')}
      </Txt>
    </Surface>
  );
};
