import { useTranslation } from 'react-i18next';
import { EmptyState, Surface } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';
import { CheckMark } from '@vst/beam-icons/illustrative-icons';

import useNavigate from '@mfe/services/navigation';
import { Storage, useScreenResolution } from '@mfe/shared/util';
import { StackLink } from '@mfe/legacy/mv/utils/Navigation';
import { Container } from '@mfe/shared/components';
import {
  refetchCurrentAddOns,
  resetSubmit,
  selectAddOns,
} from '@mfe/to-be-migrated/redux/addOns';
import { scrollToTop } from '@mfe/shared/redux/utils';

import styles from './styles.module.scss';

export const OrderSuccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShopAddons');
  const { goTo, replace } = useNavigate();

  const { addOnOrderId } = useSelector(selectAddOns);

  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  return (
    <Container>
      <Surface
        className={styles['container']}
        data-cy="order-confirmation-success"
        radius="16px"
      >
        <EmptyState
          py={'40px'}
          px={isMobile ? '24px' : '40px'}
          icon={CheckMark}
          title={t('success.title')}
          description={t('success.description')}
          actions={{
            primary: {
              label: t('success.primaryLabel'),
              onClick: () => {
                dispatch(refetchCurrentAddOns(addOnOrderId as string));
                dispatch(resetSubmit());
                goTo('ShopAddons');
                dispatch(scrollToTop());
              },
              variant: 'primary',
            },
            secondary: {
              label: t('success.secondaryLabel'),
              onClick: () => {
                dispatch(refetchCurrentAddOns(addOnOrderId as string));
                dispatch(resetSubmit());
                Storage.setItem('showInternetPlan', true);
                replace(`/${StackLink.Profile}`);
              },
              variant: 'secondary',
            },
          }}
          actionsLayout={'vertical'}
        />
      </Surface>
    </Container>
  );
};
