import { gql } from '@apollo/client';

export const GET_CURRENT_ADD_ONS = gql`
  query getCurrentAddOns($input: GetCurrentAddOnsInput) {
    getCurrentAddOns(input: $input) {
      name
      description
      state
      kind
      price
      discountName
      discountEndDate
      discountAmount
      priceWithDiscount
      productInstanceId
      isBundle
    }
  }
`;

export const GET_ADDON_OFFERS = gql`
  query getAddOnOffers {
    getAddOnOffers {
      id
      kind
      name
      description
      characteristics {
        name
        value
      }
      discounts {
        total_discounts {
          name
          duration
          amount {
            value
            currency {
              minor_units
              numeric_code
              major_unit_symbol
              alphabetic_code
            }
          }
        }
        itemized_discounts {
          name
          duration
          amount {
            value
            currency {
              minor_units
              numeric_code
              major_unit_symbol
              alphabetic_code
            }
          }
        }
      }
      offer_id
      marketing_copy {
        ui_behaviors {
          characteristics {
            name
            value
          }
        }
        translations {
          language
          characteristics {
            name
            value
          }
        }
      }
      prices {
        amount {
          value
        }
      }
    }
  }
`;

export const PURCHASE_ADD_ON = gql`
  mutation purchaseAddOn($input: PurchaseAddOnInput!) {
    purchaseAddOn(input: $input) {
      success
      message
      orderId
    }
  }
`;

export const GET_AVAILABLE_PHONE_NUMBERS = gql`
  query getAvailableTelephoneNumbers(
    $input: GetAvailableTelephoneNumbersInput!
  ) {
    getAvailableTelephoneNumbers(input: $input) {
      phoneNumber
      prefix
    }
  }
`;

export const RESERVE_PHONE_NUMBER = gql`
  mutation reserveTelephoneNumber($input: ReserveTelephoneNumberInput!) {
    reserveTelephoneNumber(input: $input) {
      reservedPhoneNumber
      reservedTime
      success
    }
  }
`;

export const CONFIGURE_ADDON = gql`
  mutation configureAddon($input: ConfigureAddonInput!) {
    configureAddon(input: $input) {
      success
    }
  }
`;

export const GET_SCRUBBED_ADDRESS = gql`
  query getScrubbedAddress($address: GetScrubbedAddressInput!) {
    getScrubbedAddress(address: $address) {
      addressLines
      municipality
      region
      country
      postalCode
      countryCode
      processStatus
      recommendation
    }
  }
`;

export const SEND_SURVEY_RESULTS_TO_QUALTRICS = gql`
  mutation sendSurveyResultsToQualtrics(
    $input: SendSurveyResultsToQualtricsInput!
  ) {
    sendSurveyResultsToQualtrics(input: $input) {
      success
    }
  }
`;

export const REMOVE_ADDON = gql`
  mutation removeAddOn($input: RemoveAddOnInput) {
    removeAddOn(input: $input) {
      success
      message
      orderId
    }
  }
`;
