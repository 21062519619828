import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StandardModal, Txt } from '@vst/beam';

import styles from './styles.module.scss';

interface E911IncorrectAddressModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const E911IncorrectAddressModal: FC<E911IncorrectAddressModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('ShopAddons');

  return (
    <div data-cy={'e911-incorrect-address-modal'}>
      <StandardModal
        size={'medium'}
        header={t('voice.emergencyScrubbedAddress.incorrectModal.title')}
        isOpen={isOpen}
        onClose={onClose}
        actions={{
          primary: {
            label: t(
              'voice.emergencyScrubbedAddress.incorrectModal.primaryButton.label'
            ),
            onClick: onClose,
          },
        }}
        surfaceProps={{ className: styles['incorrect-address-modal'] }}
      >
        <Txt variant={'bodySmallRegular'} style={{ margin: '0px' }}>
          {t('voice.emergencyScrubbedAddress.incorrectModal.description')}
        </Txt>
      </StandardModal>
    </div>
  );
};
