import { useTranslation } from 'react-i18next';
import { EmptyState, InlineLink, Surface, Txt } from '@vst/beam';
import useNavigate from '@mfe/services/navigation';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { useScreenResolution } from '@mfe/shared/util';

import styles from './styles.module.scss';

import { useDispatch } from 'react-redux';
import { getAddOnOffers, resetSubmit } from '@mfe/to-be-migrated/redux/addOns';
import { Container } from '@mfe/shared/components';

const CustomerSupportTelLink = () => {
  const { t } = useTranslation('ShopAddons');

  return (
    <Txt as="span" variant={'bodyLargeRegular'}>
      {t('error.description')}
      <InlineLink
        variant="primary"
        className={styles['customer-support-tel-link']}
        href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
      >
        {t('Global:callCustomerSupportPhoneNumberDisplay')}
      </InlineLink>
    </Txt>
  );
};

export const OrderError = () => {
  const { t } = useTranslation('ShopAddons');
  const { goBack } = useNavigate();
  const { isSmall, isExtraSmall } = useScreenResolution();
  const dispatch = useDispatch();

  const isMobile = isSmall || isExtraSmall;

  return (
    <Container>
      <Surface
        className={`${styles['container']} ${styles['error']}`}
        data-cy="order-confirmation-error"
        radius="16px"
      >
        <EmptyState
          py={'40px'}
          px={isMobile ? '24px' : '40px'}
          icon={Alert}
          title={t('error.title')}
          description={<CustomerSupportTelLink />}
          actions={{
            primary: {
              label: t('error.button'),
              onClick: () => {
                dispatch(resetSubmit());
                dispatch(getAddOnOffers());

                goBack();
              },
              variant: 'primary',
            },
          }}
        />
      </Surface>
    </Container>
  );
};
